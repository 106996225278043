// import bgImg from "../images/home-background.jpg";
// import ankaraImg from "../images/city/ankara.webp";
// import istanbulImg from "../images/city/istanbul.webp";
// import diyarbakirImg from "../images/city/diyarbakir.jpg";
// import izmirImg from "../images/city/izmir.jpg";
// import companyLogo2 from "../images/company-logo-02.png";
// import companyLogo3 from "../images/company-logo-03.png";
// import CustomCounter from "../components/CustomCounter";
// import { Link, useNavigate } from "react-router-dom";
// import PlacesAutoComplete from "@/components/Places";
// import { useEffect, useState } from "react";
// import { number } from "yup";
// import Subscribtion from "@/components/Subscription/Subscribtion";
// import { fetchStatistics } from "@/api/public";
// import { debug } from "console";

// const Home = () => {
//   const [statistics, setStatistics] = useState({
//     jobApplications: 0,
//     jobPostings: 0,
//     jobConnections: 0,
//   });
//   const [statistics, setStatistics] = useState({
//     jobApplications: 0,
//     jobPostings: 0,
//     jobConnections: 0,
//     lastFiveJobs: [],
//     cities: [],
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const getStats = async () => {
//       try {
//         const response = await fetchStatistics();

//         setStatistics({
//           jobApplications: response.data.total_applications_applied,
//           jobPostings: response.data.total_jobs_posted,
//           jobConnections: response.data.hired_count,
//           lastFiveJobs: response.data.last_five_jobs_posted,
//           cities: response.data.city_count,
//         });
//         setLoading(false);
//       } catch (error) {
//         if (error instanceof Error) {
//           setError(error.message);
//         } else {
//           setError("An unexpected error occurred");
//         }
//         setLoading(false);
//       }
//     };

//     getStats();
//   }, []);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   return (
//     <>
//       <div
//         className="intro-banner"
//         style={{ backgroundImage: `url("/home-background.jpg")` }}
//       >
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="banner-headline">
//                 <h3>
//                   <strong>Kariyer fırsatlarını bizimle keşfet!</strong>
//                   <br />

//                   <span>
//                     Binlerce iş ilanı arasında, iş ya da işçi mi arıyorsun?
//                     <strong className="color">ParttimeJET</strong> ile aradığına
//                     jet hızında ulaş.
//                   </span>
//                 </h3>
//               </div>
//             </div>
//           </div>

//           <div className="container">
//             <div className="row flex-lg-row flex-column margin-top-55">
//               <div className="with-autocomplete col-lg-5 col-12 mb-3 border-0 p-0 border-left-style position-relative">
//                 <label
//                   htmlFor="autocomplete-input-1"
//                   className="label-title ripple-effect mb-3"
//                 >
//                   Hangi şehir?
//                 </label>
//                 <PlacesAutoComplete />
//                 <div className="input-container"></div>
//               </div>

//               <div className="with-autocomplete col-lg-7 col-12 mb-3 border-0 p-0 border-right-style">
//                 <label
//                   htmlFor="autocomplete-input-2"
//                   className="label-title ripple-effect mb-3"
//                 >
//                   Aradığınız iş ya da firma?
//                 </label>
//                 <div className="input-container position-relative d-flex">
//                   <input
//                     type="text"
//                     className="form-control input-style-secondary"
//                     placeholder="İş tanımını girin"
//                   />
//                   <Link to="/job-seeker/job-posting">
//                     <button
//                       className="button ripple-effect search-button"
//                       // onClick={() => navigate("/job-seeker/job-posting")}
//                     >
//                       JET Hızıyla Ara
//                     </button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="row">
//             <div className="col-md-12">
//               <ul className="intro-stats margin-top-25 hide-under-992px">
//                 <li>
//                   <CustomCounter
//                     end={statistics.jobApplications}
//                     duration={1500}
//                   />
//                   <span>İş Başvurusu Yapıldı</span>
//                 </li>
//                 <li>
//                   <CustomCounter end={statistics.jobPostings} duration={1500} />
//                   <span>İş İlanı Yayınlandı</span>
//                 </li>
//                 <li>
//                   <CustomCounter
//                     end={statistics.jobConnections}
//                     duration={1500}
//                   />
//                   <span>İş Bağlandı</span>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div
//           className="background-image-container"
//           style={{ backgroundImage: `url("/home-background.jpg")` }}
//         ></div>
//       </div>

//       <div className="section padding-top-65 white">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="section-headline centered margin-bottom-15">
//                 <h3>Çok Aranan Meslekler</h3>
//               </div>

//               <div className="categories-container">
//                 <Link to="/job-seeker/job-posting" className="category-box">
//                   <div className="category-box-icon">
//                     <i className="icon-line-awesome-file-code-o"></i>
//                   </div>
//                   <div className="category-box-counter">612</div>
//                   <div className="category-box-content">
//                     <h3>Web Designer</h3>
//                     <p>Yazılım, website ile ilgili aranan geliştiriciler</p>
//                   </div>
//                 </Link>

//                 <Link to="/job-seeker/job-posting" className="category-box">
//                   <div className="category-box-icon">
//                     <i className="icon-line-awesome-cloud-upload"></i>
//                   </div>
//                   <div className="category-box-counter">113</div>
//                   <div className="category-box-content">
//                     <h3>Şube Müdürü</h3>
//                     <p>Firmaların şubelerine aranan yöneticiler</p>
//                   </div>
//                 </Link>

//                 <Link to="/job-seeker/job-posting" className="category-box">
//                   <div className="category-box-icon">
//                     <i className="icon-line-awesome-suitcase"></i>
//                   </div>
//                   <div className="category-box-counter">186</div>
//                   <div className="category-box-content">
//                     <h3>Teknik Servis Elemanı</h3>
//                     <p>Firmaların ihtiyacı olan teknik konulara destekçiler</p>
//                   </div>
//                 </Link>

//                 <Link to="/job-seeker/job-posting" className="category-box">
//                   <div className="category-box-icon">
//                     <i className="icon-line-awesome-pencil"></i>
//                   </div>
//                   <div className="category-box-counter">298</div>
//                   <div className="category-box-content">
//                     <h3>Tezgahtar</h3>
//                     <p>Şubelerin temel ihtiyaçları olan çalışan grubu</p>
//                   </div>
//                 </Link>

//                 <Link to="/job-seeker/job-posting" className="category-box">
//                   <div className="category-box-icon">
//                     <i className="icon-line-awesome-pie-chart"></i>
//                   </div>
//                   <div className="category-box-counter">549</div>
//                   <div className="category-box-content">
//                     <h3>Mutfak Elemanı</h3>
//                     <p>Mutfakların ihtiyacı olan aşçı, garson, komi...</p>
//                   </div>
//                 </Link>

//                 <Link to="/job-seeker/job-posting" className="category-box">
//                   <div className="category-box-icon">
//                     <i className="icon-line-awesome-image"></i>
//                   </div>
//                   <div className="category-box-counter">873</div>
//                   <div className="category-box-content">
//                     <h3>Depo Elemanı</h3>
//                     <p>Depolarda çalışacak yöneticiler ve işçiler</p>
//                   </div>
//                 </Link>

//                 <Link to="/job-seeker/job-posting" className="category-box">
//                   <div className="category-box-icon">
//                     <i className="icon-line-awesome-bullhorn"></i>
//                   </div>
//                   <div className="category-box-counter">125</div>
//                   <div className="category-box-content">
//                     <h3>Engelliler İçin İlanlar</h3>
//                     <p>Engelli bireylere uygun iş ilanları</p>
//                   </div>
//                 </Link>

//                 <Link to="/job-seeker/job-posting" className="category-box">
//                   <div className="category-box-icon">
//                     <i className="icon-line-awesome-graduation-cap"></i>
//                   </div>
//                   <div className="category-box-counter">445</div>
//                   <div className="category-box-content">
//                     <h3>Kasiyer</h3>
//                     <p>
//                       Mağazaların ihtiyaçlarına yönelik çalışacak kasiyerler
//                     </p>
//                   </div>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="section gray margin-top-45 padding-top-65 padding-bottom-75">
//         <div className="container">
//           <div className="row">
//             <div className="col-xl-12">
//               <div className="section-headline margin-top-0 margin-bottom-35">
//                 <h3>İş İlanları</h3>
//                 <Link to="/job-seeker/job-posting" className="headline-link">
//                   Tüm İlanları Görüntüle
//                 </Link>
//               </div>

//               <div className="listings-container compact-list-layout margin-top-35">
//                 <Link
//                   to="/job-seeker/job-posting"
//                   className="job-listing with-apply-button"
//                 >
//                   <div className="job-listing-details">
//                     <div className="job-listing-company-logo">
//                       <img src={companyLogo3} alt="" />
//                     </div>

//                     <div className="job-listing-description">
//                       <h3 className="job-listing-title">Şef Garson</h3>

//                       <div className="job-listing-footer">
//                         <ul>
//                           <li>
//                             <i className="icon-material-outline-business"></i>{" "}
//                             Burger King{" "}
//                             <div
//                               className="verified-badge"
//                               title="Doğrulanmış İşveren"
//                               data-tippy-placement="top"
//                             ></div>
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-location-on"></i>{" "}
//                             İstanbul
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-business-center"></i>{" "}
//                             Tam Zamanlı
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-access-time"></i>{" "}
//                             2 gün önce
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <span className="list-apply-button ripple-effect">
//                       Başvuru Yap
//                     </span>
//                   </div>
//                 </Link>

//                 <Link
//                   to="/job-seeker/job-posting"
//                   className="job-listing with-apply-button"
//                 >
//                   <div className="job-listing-details">
//                     <div className="job-listing-company-logo">
//                       <img src={companyLogo3} alt="" />
//                     </div>

//                     <div className="job-listing-description">
//                       <h3 className="job-listing-title">Şef Garson</h3>

//                       <div className="job-listing-footer">
//                         <ul>
//                           <li>
//                             <i className="icon-material-outline-business"></i>{" "}
//                             Burger King{" "}
//                             <div
//                               className="verified-badge"
//                               title="Doğrulanmış İşveren"
//                               data-tippy-placement="top"
//                             ></div>
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-location-on"></i>{" "}
//                             İstanbul
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-business-center"></i>{" "}
//                             Tam Zamanlı
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-access-time"></i>{" "}
//                             2 gün önce
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <span className="list-apply-button ripple-effect">
//                       Başvuru Yap
//                     </span>
//                   </div>
//                 </Link>
//                 <Link
//                   to="/job-seeker/job-posting"
//                   className="job-listing with-apply-button"
//                 >
//                   <div className="job-listing-details">
//                     <div className="job-listing-company-logo">
//                       <img src={companyLogo2} alt="" />
//                     </div>

//                     <div className="job-listing-description">
//                       <h3 className="job-listing-title">Barista</h3>

//                       <div className="job-listing-footer">
//                         <ul>
//                           <li>
//                             <i className="icon-material-outline-business"></i>{" "}
//                             Kahveci
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-location-on"></i>{" "}
//                             Ankara
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-business-center"></i>{" "}
//                             Yarı Zamanlı
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-access-time"></i>{" "}
//                             Bugün
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <span className="list-apply-button ripple-effect">
//                       Başvuru Yap
//                     </span>
//                   </div>
//                 </Link>

//                 <Link
//                   to="/job-seeker/job-posting"
//                   className="job-listing with-apply-button"
//                 >
//                   <div className="job-listing-details">
//                     <div className="job-listing-company-logo">
//                       <img src={companyLogo2} alt="" />
//                     </div>

//                     <div className="job-listing-description">
//                       <h3 className="job-listing-title">Barista</h3>

//                       <div className="job-listing-footer">
//                         <ul>
//                           <li>
//                             <i className="icon-material-outline-business"></i>{" "}
//                             Kahveci
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-location-on"></i>{" "}
//                             Ankara
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-business-center"></i>{" "}
//                             Yarı Zamanlı
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-access-time"></i>{" "}
//                             Bugün
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <span className="list-apply-button ripple-effect">
//                       Başvuru Yap
//                     </span>
//                   </div>
//                 </Link>

//                 <Link
//                   to="/job-seeker/job-posting"
//                   className="job-listing with-apply-button"
//                 >
//                   <div className="job-listing-details">
//                     <div className="job-listing-company-logo">
//                       <img src={companyLogo3} alt="" />
//                     </div>

//                     <div className="job-listing-description">
//                       <h3 className="job-listing-title">Şef Garson</h3>

//                       <div className="job-listing-footer">
//                         <ul>
//                           <li>
//                             <i className="icon-material-outline-business"></i>{" "}
//                             Burger King{" "}
//                             <div
//                               className="verified-badge"
//                               title="Doğrulanmış İşveren"
//                               data-tippy-placement="top"
//                             ></div>
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-location-on"></i>{" "}
//                             İstanbul
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-business-center"></i>{" "}
//                             Tam Zamanlı
//                           </li>
//                           <li>
//                             <i className="icon-material-outline-access-time"></i>{" "}
//                             2 gün önce
//                           </li>
//                         </ul>
//                       </div>
//                     </div>

//                     <span className="list-apply-button ripple-effect">
//                       Başvuru Yap
//                     </span>
//                   </div>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="section margin-top-65 margin-bottom-65">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="section-headline centered margin-top-0 margin-bottom-45">
//                 <h3>En Fazla İş Aranan İller</h3>
//               </div>
//             </div>

//             <div className="col-md-3">
//               <Link
//                 to="/job-seeker/job-posting"
//                 className="photo-box"
//                 style={{ backgroundImage: `url(${ankaraImg})` }}
//               >
//                 <div className="photo-box-content">
//                   <h3>Ankara</h3>
//                   <span>376 İş İlanı</span>
//                 </div>
//               </Link>
//             </div>

//             <div className="col-md-3">
//               <Link
//                 to="/job-seeker/job-posting"
//                 className="photo-box"
//                 style={{ backgroundImage: `url(${istanbulImg})` }}
//               >
//                 <div className="photo-box-content">
//                   <h3>İstanbul</h3>
//                   <span>645 İş İlanı</span>
//                 </div>
//               </Link>
//             </div>

//             <div className="col-md-3">
//               <Link
//                 to="/job-seeker/job-posting"
//                 className="photo-box"
//                 style={{ backgroundImage: `url(${izmirImg})` }}
//               >
//                 <div className="photo-box-content">
//                   <h3>İzmir</h3>
//                   <span>832 İş İlanı</span>
//                 </div>
//               </Link>
//             </div>

//             <div className="col-md-3">
//               <Link
//                 to="/job-seeker/job-posting"
//                 className="photo-box"
//                 style={{ backgroundImage: `url(${diyarbakirImg})` }}
//               >
//                 <div className="photo-box-content">
//                   <h3>Diyarbakır</h3>
//                   <span>513 İş İlanı</span>
//                 </div>
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="section gray padding-top-60 padding-bottom-75">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="section-headline centered margin-top-0 margin-bottom-35">
//                 <h3>Üyelik Planları</h3>
//               </div>
//             </div>

//             <div className="col-md-12">
//               <div className="billing-cycle-radios margin-bottom-70">
//                 <div className="radio billed-monthly-radio">
//                   <input
//                     id="radio-5"
//                     name="radio-payment-type"
//                     type="radio"
//                     checked
//                   />
//                   <label htmlFor="radio-5">
//                     <span className="radio-label"></span> Aylık Üyelik
//                   </label>
//                 </div>

//                 <div className="radio billed-yearly-radio">
//                   <input id="radio-6" name="radio-payment-type" type="radio" />
//                   <label htmlFor="radio-6">
//                     <span className="radio-label"></span> Yıllık Üyelik{" "}
//                     <span className="small-label">%10 indirimli</span>
//                   </label>
//                 </div>
//               </div>

//               <div className="pricing-plans-container">
//                 <div className="pricing-plan">
//                   <h3>Ücretsiz Plan</h3>
//                   <p className="margin-top-10">
//                     ParttimeJET hizmetlerinden sınırlı erişim hakkına sahip
//                     olduğunuz plan.
//                   </p>
//                   <div className="pricing-plan-label billed-monthly-label">
//                     <strong>0₺</strong>/ aylık
//                   </div>
//                   <div className="pricing-plan-label billed-yearly-label">
//                     <strong>0₺</strong>/ yıllık
//                   </div>
//                   <div className="pricing-plan-features">
//                     <strong>Ücretsiz Plan İçeriği</strong>
//                     <ul>
//                       <li>1 İlan</li>
//                       <li>1 Konum</li>
//                       <li>5 PT İşbaşı</li>
//                     </ul>
//                   </div>
//                   <Link
//                     to="/payment"
//                     className="button full-width margin-top-20"
//                   >
//                     Zaten Sahipsiniz
//                   </Link>
//                 </div>

//                 <div className="pricing-plan recommended">
//                   <div className="recommended-badge">Önerilen</div>
//                   <h3>PRO Plan</h3>
//                   <p className="margin-top-10">
//                     ParttimeJET hizmetlerinden bir çoğuna erişim hakkına sahip
//                     olduğunuz plan.
//                   </p>
//                   <div className="pricing-plan-label billed-monthly-label">
//                     <strong>1.000₺</strong>/ aylık
//                   </div>
//                   <div className="pricing-plan-label billed-yearly-label">
//                     <strong>11.000₺</strong>/ yıllık
//                   </div>
//                   <div className="pricing-plan-features">
//                     <strong>PRO Plan İçeriği</strong>
//                     <ul>
//                       <li>5 İlan</li>
//                       <li>5 Konum</li>
//                       <li>50 PT İşbaşı</li>
//                     </ul>
//                   </div>
//                   <Link
//                     to="/payment"
//                     className="button full-width margin-top-20"
//                   >
//                     Satın Al
//                   </Link>
//                 </div>

//                 <div className="pricing-plan">
//                   <h3>VIP Plan</h3>
//                   <p className="margin-top-10">
//                     ParttimeJET hizmetlerine sınırsız erişim hakkına sahip
//                     olduğunuz plan.
//                   </p>
//                   <div className="pricing-plan-label billed-monthly-label">
//                     <strong>2.000₺</strong>/ aylık
//                   </div>
//                   <div className="pricing-plan-label billed-yearly-label">
//                     <strong>20.000₺</strong>/ yıllık
//                   </div>
//                   <div className="pricing-plan-features">
//                     <strong>VIP Plan İçeriği</strong>
//                     <ul>
//                       <li>Limitsiz İlan Hakkı</li>
//                       <li>Limitsiz Konum Hakkı</li>
//                       <li>Limitsiz PT İşbaşı</li>
//                     </ul>
//                   </div>
//                   <Link
//                     to="/payment"
//                     className="button full-width margin-top-20"
//                   >
//                     Satın Al
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Subscribtion />
//     </>
//   );
// };

// export default Home;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomCounter from "../components/CustomCounter";
import PlacesAutoComplete from "@/components/Places";
import Subscribtion from "@/components/Subscription/Subscribtion";
import { fetchStatistics } from "@/api/public";
import noAvatar from "../images/company-logo-05.png";
// Define the types for your data
interface Role {
  role: string;
  role_count: number;
}

interface Company {
  id: number;
  logo_id: string;
  name: string;
}

interface Job {
  id: number;
  title: string;
  company: Company;
  province: { name: string };
  type: string;
}

interface City {
  city: string;
  city_count: number;
  cityImg: string;
}

interface Statistics {
  jobApplications: number;
  jobPostings: number;
  jobConnections: number;
  roles: Role[];
  lastFiveJobs: Job[];
  cities: City[];
}

// Dynamic Home component
const Home = () => {
  const [statistics, setStatistics] = useState<Statistics>({
    jobApplications: 0,
    jobPostings: 0,
    jobConnections: 0,
    roles: [],
    lastFiveJobs: [],
    cities: [],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getStats = async () => {
      try {
        const response = await fetchStatistics();
        const data = response.data;

        setStatistics({
          jobApplications: data.total_applications_applied,
          jobPostings: data.total_jobs_posted,
          jobConnections: data.hired_count,
          roles: data.role_count,
          lastFiveJobs: data.last_five_jobs_posted,
          cities: data.city_count,
        });
        setLoading(false);
      } catch (error) {
        setError(
          error instanceof Error
            ? error.message
            : "An unexpected error occurred"
        );
        setLoading(false);
      }
    };

    getStats();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <div
        className="intro-banner"
        style={{ backgroundImage: `url("/home-background.jpg")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner-headline">
                <h3>
                  <strong>Kariyer fırsatlarını bizimle keşfet!</strong>
                  <br />
                  <span>
                    Binlerce iş ilanı arasında, iş ya da işçi mi arıyorsun?
                    <strong className="color">ParttimeJET</strong> ile aradığına
                    jet hızında ulaş.
                  </span>
                </h3>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row flex-lg-row flex-column margin-top-55">
              <div className="with-autocomplete col-lg-5 col-12 mb-3 border-0 p-0 border-left-style position-relative">
                <label
                  htmlFor="autocomplete-input-1"
                  className="label-title ripple-effect mb-3"
                >
                  Hangi şehir?
                </label>
                <PlacesAutoComplete />
              </div>
              <div className="with-autocomplete col-lg-7 col-12 mb-3 border-0 p-0 border-right-style">
                <label
                  htmlFor="autocomplete-input-2"
                  className="label-title ripple-effect mb-3"
                >
                  Aradığınız iş ya da firma?
                </label>
                <div className="input-container position-relative d-flex">
                  <input
                    type="text"
                    className="form-control input-style-secondary"
                    placeholder="İş tanımını girin"
                  />
                  <Link to="/job-seeker/job-posting">
                    <button className="button ripple-effect search-button">
                      JET Hızıyla Ara
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <ul className="intro-stats margin-top-25 hide-under-992px">
                <li>
                  <CustomCounter
                    end={statistics.jobApplications}
                    duration={1500}
                  />
                  <span>İş Başvurusu Yapıldı</span>
                </li>
                <li>
                  <CustomCounter end={statistics.jobPostings} duration={1500} />
                  <span>İş İlanı Yayınlandı</span>
                </li>
                <li>
                  <CustomCounter
                    end={statistics.jobConnections}
                    duration={1500}
                  />
                  <span>İş Bağlandı</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Dynamic Job Categories */}
      <div className="section padding-top-65 white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-headline centered margin-bottom-15">
                <h3>Çok Aranan Meslekler</h3>
              </div>
              <div className="categories-container">
                {statistics.roles.map((role) => (
                  <Link
                    key={role.role}
                    to="/job-seeker/job-posting"
                    className="category-box"
                  >
                    <div className="category-box-icon">
                      <i className="icon-line-awesome-file-code-o"></i>
                    </div>
                    <div className="category-box-counter">
                      {role.role_count}
                    </div>
                    <div className="category-box-content">
                      <h3>{role.role}</h3>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Dynamic Job Listings */}
      <div className="section gray margin-top-45 padding-top-65 padding-bottom-75">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-headline margin-top-0 margin-bottom-35">
                <h3>İş İlanları</h3>
                <Link to="/job-seeker/job-posting" className="headline-link">
                  Tüm İlanları Görüntüle
                </Link>
              </div>

              <div className="listings-container compact-list-layout margin-top-35">
                {statistics.lastFiveJobs.map((job) => (
                  <Link
                    key={job.id}
                    to="/job-seeker/job-posting"
                    className="job-listing with-apply-button"
                  >
                    <div className="job-listing-details">
                      <div className="job-listing-company-logo">
                        <img src={noAvatar} alt={job.company.name} />
                      </div>
                      <div className="job-listing-description">
                        <h3 className="job-listing-title">{job.title}</h3>
                        <div className="job-listing-footer">
                          <ul>
                            <li>
                              <i className="icon-material-outline-business"></i>{" "}
                              {job.company.name}
                            </li>
                            <li>
                              <i className="icon-material-outline-location-on"></i>{" "}
                              {job.province.name}
                            </li>
                            <li>
                              <i className="icon-material-outline-business-center"></i>{" "}
                              {job.type}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <span className="list-apply-button ripple-effect">
                        Başvuru Yap
                      </span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Dynamic Cities */}
      <div className="section margin-top-65 margin-bottom-65">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-headline centered margin-top-0 margin-bottom-45">
                <h3>En Fazla İş Aranan İller</h3>
              </div>
            </div>
            {statistics.cities.map((city) => (
              <div key={city.city} className="col-md-3">
                <Link
                  to="/job-seeker/job-posting"
                  className="photo-box"
                  style={{ backgroundImage: `url(${city.cityImg})` }}
                >
                  <div className="photo-box-content">
                    <h3>{city.city}</h3>
                    <span>{city.city_count} İş İlanı</span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Subscribtion />
    </>
  );
};

export default Home;
